import { StoreAction } from "../../@core/constants/action";

const initialState = { broadcastList: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_BROADCAST_LIST:
      return { ...state, broadcastList: action.payload };
    default:
      return state;
  }
}
