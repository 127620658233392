// ** Reducers Imports
import layout from "./layout";
import chatReducer from "./reducers/chatReducer";
import commonReducer from "./reducers/commonReducer";
import deviceReducer from "./reducers/deviceReducer";
import chatBotReducer from "./reducers/chatBotReducer";
import templateReducer from "./reducers/templateReducer";
import customerReducer from "./reducers/customerReducer";
import broadcastReducer from "./reducers/broadcastReducer";
import quickReplyReducer from "./reducers/quickReplyReducer";
import superadminReducer from "./reducers/superadminReducer";

const rootReducer = {
  layout,
  chatReducer,
  commonReducer,
  deviceReducer,
  chatBotReducer,
  templateReducer,
  customerReducer,
  superadminReducer,
  quickReplyReducer,
  broadcastReducer,
};

export default rootReducer;
